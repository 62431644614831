@tailwind base;
@tailwind components;
@tailwind utilities;

.content p {
  @apply my-2;
}

.content p strong {
  /* @apply border-solid; */
  /* @apply border-b-2; */
  /* @apply border-white; */
  @apply font-bold;
  /* @apply text-sherpa-blue-100; */
}

.content ul {
  @apply my-6;
}
